import React, { useState } from 'react';
import { FaRepublican, FaDemocrat, FaStar } from 'react-icons/fa';
import { FcLibrary } from "react-icons/fc";
import { PaperClipIcon } from '@heroicons/react/20/solid';
import '../App.css';

const BillCard = ({ title, simple_summary, in_depth_summary, impacts_and_risks, bill_id, chamber, sponsor, cosponsors, pdf_link, selectedStates, actions, keywords }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [isTimelineOpen, setIsTimelineOpen] = useState(false);

  const renderActions = (actions) => {
    if (typeof actions === 'string') {
      try {
        actions = JSON.parse(actions);
      } catch (error) {
        console.error('Failed to parse actions string:', error);
        return null; // Or some fallback UI
      }
    }
  
    if (!Array.isArray(actions)) {
      console.error('Actions is not an array:', actions);
      return null; // Or some fallback UI
    }
  
    return (
      <div className="space-y-8 relative before:absolute before:inset-0 before:ml-5 before:-translate-x-px md:before:ml-[8.75rem] md:before:translate-x-0 before:h-full before:w-0.5 before:bg-gradient-to-b before:from-transparent before:via-slate-300 before:to-transparent">
        {actions.map((action, index) => (
          <div key={index} className="relative">
            <div className="md:flex items-center md:space-x-4 mb-3">
              <div className="flex items-center space-x-4 md:space-x-2 md:space-x-reverse">
                <div className="flex items-center justify-center w-10 h-10 rounded-full bg-white shadow md:order-1">
                  <FcLibrary className="text-2xl" />
                </div>
                <time className="font-normal font-sm text-med text-black md:w-28">{action.actionDate}</time>
              </div>
              <div className="flex flex-col ml-14 md:ml-44">
                <div className="text-black"><span className="text-black text-sm font-normal">{action.type}</span></div>
                <div className="text-slate-500 text-sm font-normal">{action.text}</div>
              </div>
            </div>
          </div>
        ))}
      </div>
    );
  };

  const cosponsorList = cosponsors ? cosponsors.split('], ').map(c => c + ']') : [];

  const extractStateCode = (str) => {
    if (!str) return '';
    const match = str.match(/\[.*-([A-Z]{2})/);
    return match ? match[1] : '';
  };

  const extractDistrict = (str) => {
    if (!str) return '';
    const match = str.match(/\[.*-[A-Z]{2}-(.*)\]/);
    return match ? match[1] : '';
  };

  const extractParty = (str) => {
    if (!str) return '';
    const match = str.match(/\[([A-Z])-/);
    return match ? match[1] : '';
  };

  const isHighlighted = (person) => {
    const stateCode = extractStateCode(person);
    return selectedStates.includes(stateCode);
  };

  const getPartyIcon = (party) => {
    switch (party) {
      case 'R':
        return <FaRepublican color="red" />;
      case 'D':
        return <FaDemocrat color="blue" />;
      case 'I':
        return <FaStar color="green" />;
      default:
        return null;
    }
  };

  const renderMainSponsor = (sponsor) => {
    const party = extractParty(sponsor);
    const icon = getPartyIcon(party);
    const name = sponsor.split(' [')[0];
    const stateInfo = extractStateCode(sponsor);
    const districtInfo = extractDistrict(sponsor);

    return (
      <div className={`flex items-center ${isHighlighted(sponsor) ? 'bg-yellow-200' : ''}`}>
        <div className="flex items-center justify-left w-1/12">{icon}</div>
        <div className="w-6/12 pl-2">{name}</div>
        <div className="w-3/12 pl-2">{stateInfo} - {districtInfo}</div>
      </div>
    );
  };

  const renderCosponsors = (cosponsors) => {
    return cosponsors.map((cosponsor, index) => {
      const party = extractParty(cosponsor);
      const icon = getPartyIcon(party);
      const name = cosponsor.split(' [')[0];
      const stateInfo = extractStateCode(cosponsor);
      let districtInfo = extractDistrict(cosponsor);

      // Check if it's the last cosponsor and remove any trailing brackets
      if (index === cosponsors.length - 1 && districtInfo.endsWith(']')) {
        districtInfo = districtInfo.slice(0, -1);
      }

      return (
        <div key={index} className={`flex items-center mb-2 ${isHighlighted(cosponsor) ? 'bg-yellow-200' : ''}`}>
          <div className="flex items-center justify-left w-1/12">{icon}</div>
          <div className="w-6/12 pl-2">{name}</div>
          <div className="w-3/12 pl-2">{stateInfo} - {districtInfo}</div>
        </div>
      );
    });
  };

  return (
    <div className="bg-white shadow-md mb-6 w-full rounded-lg">
      <div className="collapse collapse-arrow rounded-lg">
        <input
          type="checkbox"
          className="peer"
          checked={isOpen}
          onChange={() => setIsOpen(!isOpen)}
        />
        <div
          className={`collapse-title text-xl font-medium bg-white text-black ${isOpen ? '' : 'truncate'} rounded-t-lg`}
          onClick={() => setIsOpen(!isOpen)}
        >
          {title}
        </div>
        <div className="collapse-content rounded-b-lg">
          <div className="mt-4 border-t border-gray-100">
            <dl className="divide-y divide-gray-100">
              <div className="py-4">
                <dt className="text-med font-medium leading-6 text-black">Simple Summary</dt>
                <dd className="mt-1 text-sm leading-6 text-black">{simple_summary || "No text or summary available yet."}</dd>
              </div>
              <div className="py-4">
                <dt className="text-med font-medium leading-6 text-black">In-Depth Summary</dt>
                <dd className="mt-1 text-sm leading-6 text-black">{in_depth_summary || "No text or summary available yet."}</dd>
              </div>
              <div className="py-4">
                <dt className="text-med font-medium leading-6 text-black">Impacts and Risks</dt>
                <dd className="mt-1 text-sm leading-6 text-black">{impacts_and_risks || "No text or summary available yet."}</dd>
              </div>
   
              <div className="py-4">
                <dt className="text-med font-medium leading-6 text-black">Sponsor</dt>
                <dd className="mt-1 text-sm leading-6 text-black">
                  {sponsor ? renderMainSponsor(sponsor) : "No sponsor information available"}
                </dd>
              </div>
              <div className="py-4">
                <dt className="text-med font-medium leading-6 text-black">Co-Sponsors</dt>
                <dd className="mt-1 text-sm leading-6 text-black">
                  {cosponsorList.length > 0 ? renderCosponsors(cosponsorList) : "No co-sponsors"}
                </dd>
              </div>
              <div className="py-4">
                <dt className="text-med font-medium leading-6 text-black">Bill ID</dt>
                <dd className="mt-1 text-sm leading-6 text-black">{bill_id}</dd>
              </div>
              <div className="py-4">
                <dt className="text-med font-medium leading-6 text-black">Chamber</dt>
                <dd className="mt-1 text-sm leading-6 text-black">{chamber}</dd>
              </div>
              <div className="py-4">
                <dt className="text-med font-medium leading-6 text-black">Original Text</dt>
                <dd className="mt-2 text-sm text-black">
                  {pdf_link ? (
                   
                   <a href={pdf_link} target="_blank" rel="noopener noreferrer" className="flex items-center font-medium text-indigo-600 hover:text-indigo-500">
                   <PaperClipIcon aria-hidden="true" className="h-5 w-5 flex-shrink-0 text-gray-400 mr-2" />
                   <span className="truncate">Bill PDF</span>
                 </a>
               ) : (
                 <p>No PDF available</p>
               )}
             </dd>
           </div>
           {/* Hidden div to store keywords for filtering */}
           <div className="hidden">{keywords}</div>
           <div className="py-4">
             <div className="collapse collapse-arrow rounded-lg">
               <input
                 type="checkbox"
                 className="peer"
                 checked={isTimelineOpen}
                 onChange={() => setIsTimelineOpen(!isTimelineOpen)}
               />
               <div
                 className={`collapse-title text-xl font-medium bg-white text-black ${isTimelineOpen ? '' : 'truncate'} rounded-t-lg`}
                 onClick={() => setIsTimelineOpen(!isTimelineOpen)}
               >
                 <dt className="text-med font-medium leading-6 text-black flex items-center">Bill Timeline</dt>
               </div>
               <div className="collapse-content rounded-b-lg">
                 {renderActions(actions)}
               </div>
             </div>
           </div>
         </dl>
       </div>
     </div>
   </div>
 </div>
);
};

export default BillCard;
