import React from 'react';
import '../App.css';
import logoImage from '../assets/govtldr-logo.svg'; 

const Header = ({ searchTerm, setSearchTerm }) => {
    return (
      <header className="bg-white-100 bg-opacity-75 text-black p-4 shadow-md">
  <div className="flex justify-center items-center">
  <img src={logoImage} alt="GovTLDR Logo" className="h-32 w-auto" /> {/* Increased height */}
        </div>
      </header>
    );
  };
  
  export default Header;