import React from 'react';
import BillCard from './BillCard';

const BillList = ({ bills, selectedStates }) => {
  // Ensure bills is an array before sorting
  const sortedBills = Array.isArray(bills) ? bills.sort((a, b) => {
    const latestActionA = a.actions.length > 0 ? new Date(a.actions[0].actionDate) : new Date(0);
    const latestActionB = b.actions.length > 0 ? new Date(b.actions[0].actionDate) : new Date(0);
    return latestActionB - latestActionA;
  }) : [];

  return (
    <div className="container">
      {sortedBills.length > 0 ? (
        <div>
          {sortedBills.map((bill, index) => (
            <BillCard
              key={bill.bill_id}
              title={bill.title}
              simple_summary={bill.simple_summary}
              in_depth_summary={bill.in_depth_summary}
              impacts_and_risks={bill.impacts_and_risks}
              bill_id={bill.bill_id}
              chamber={bill.chamber}
              sponsor={bill.main_sponsor}  // Passing sponsor to BillCard
              cosponsors={bill.cosponsors}
              pdf_link={bill.pdf_link}
              selectedStates={selectedStates}  // Pass selectedStates
              actions={bill.actions}  // Pass actions
              keywords={bill.keywords}  // Pass keywords
            />
          ))}
        </div>
      ) : (
        <p>No bills found.</p>
      )}
    </div>
  );
}

export default BillList;
