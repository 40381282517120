import React, { useState, useEffect } from 'react';
import axios from 'axios';
import BillList from './components/BillList';
import Header from './components/Header';
import FilterPanel from './components/FilterPanel';
import Pagination from './components/Pagination';
import Footer from './components/Footer';
import { Dialog, DialogBackdrop, DialogPanel } from '@headlessui/react';
import { XMarkIcon, FunnelIcon } from '@heroicons/react/20/solid'; // Import FunnelIcon for the filter button
import { loadConfig } from './config';

function App() {
  const [bills, setBills] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedStates, setSelectedStates] = useState([]);
  const [selectedHouse, setSelectedHouse] = useState('');
  const [selectedParties, setSelectedParties] = useState([]);
  const [selectedKeywords, setSelectedKeywords] = useState([]); // New state for selected keywords
  const [keywords, setKeywords] = useState([]); // New state for all available keywords
  const [mobileFiltersOpen, setMobileFiltersOpen] = useState(false);
  const [stateSearch, setStateSearch] = useState('');
  const [page, setPage] = useState(1); // New state for current page
  const [perPage] = useState(20); // Number of bills per page
  const [totalPages, setTotalPages] = useState(1); // Total number of pages
  const [config, setConfig] = useState({});

  const CACHE_DURATION = 60 * 1000; // Cache duration in milliseconds (e.g., 1 minute)

  useEffect(() => {
    loadConfig().then(setConfig);
  }, []);

  useEffect(() => {
    if (!config.API_URL) return;

    const cachedData = localStorage.getItem(`bills_page_${page}_perPage_${perPage}`);
    if (cachedData) {
      const { bills, totalPages, timestamp } = JSON.parse(cachedData);
      const isCacheValid = Date.now() - timestamp < CACHE_DURATION;

      if (isCacheValid) {
        setBills(bills);
        setTotalPages(totalPages);
        extractKeywords(bills); // Extract keywords from the fetched bills
        return; // Exit early if the cache is valid
      }
    }

    axios.get(`${config.API_URL}/api/bills?page=${page}&per_page=${perPage}`)
      .then(response => {
        setBills(response.data.bills);
        setTotalPages(response.data.totalPages);
        localStorage.setItem(`bills_page_${page}_perPage_${perPage}`, JSON.stringify({
          bills: response.data.bills,
          totalPages: response.data.totalPages,
          timestamp: Date.now()
        }));
        extractKeywords(response.data.bills); // Extract keywords from the fetched bills
      })
      .catch(error => {
        console.error('Error fetching bills:', error);
      });
  }, [config.API_URL, page, perPage]);

  const extractKeywords = (bills) => {
    const allKeywords = new Set();
    bills.forEach(bill => {
      if (bill.keywords) {
        bill.keywords.split(', ').forEach(keyword => allKeywords.add(keyword));
      }
    });
    setKeywords([...allKeywords]);
  };

  const clearFilters = () => {
    setSearchTerm('');
    setSelectedStates([]);
    setSelectedHouse('');
    setSelectedParties([]);
    setSelectedKeywords([]); // Clear selected keywords
    setStateSearch('');
  };

  const extractStateCode = (str) => {
    if (!str) return '';
    const match = str.match(/\[.*-([A-Z]{2})-\d+\]/);
    return match ? match[1] : '';
  };

  const extractParty = (str) => {
    if (!str) return '';
    const match = str.match(/\[([A-Z])-/);
    return match ? match[1] : '';
  };

  const filteredBills = bills.filter(bill => {
    const titleMatch = bill.title.toLowerCase().includes(searchTerm.toLowerCase());
    const stateMatch = selectedStates.length ? selectedStates.some(state => {
      const sponsorState = extractStateCode(bill.sponsor || '');
      const cosponsorStates = bill.cosponsors ? bill.cosponsors.split(', ').map(cosponsor => extractStateCode(cosponsor)) : [];
      return state === sponsorState || cosponsorStates.includes(state);
    }) : true;
    const houseMatch = selectedHouse ? bill.chamber.toLowerCase() === selectedHouse : true;
    const partyMatch = selectedParties.length ? selectedParties.some(party => {
      const sponsorParty = extractParty(bill.sponsor || '');
      const cosponsorParties = bill.cosponsors ? bill.cosponsors.split(', ').map(cosponsor => extractParty(cosponsor)) : [];
      return party === sponsorParty || cosponsorParties.includes(party);
    }) : true;
    const keywordMatch = selectedKeywords.length ? selectedKeywords.some(keyword => (bill.keywords || '').includes(keyword)) : true;

    return titleMatch && stateMatch && houseMatch && partyMatch && keywordMatch;
  });

  return (
    <div className="bg-white min-h-screen flex flex-col">
      <Header searchTerm={searchTerm} setSearchTerm={setSearchTerm} />
      <div className="flex flex-1">
        {/* Mobile filter button */}
        <button
          type="button"
          onClick={() => setMobileFiltersOpen(true)}
          className="lg:hidden fixed bottom-4 right-4 z-50 p-4 bg-blue-600 text-white rounded-full shadow-lg"
        >
          <FunnelIcon className="h-6 w-6" />
        </button>

        {/* Mobile filter dialog */}
        <Dialog open={mobileFiltersOpen} onClose={setMobileFiltersOpen} className="relative z-40 lg:hidden">
          <DialogBackdrop className="fixed inset-0 bg-black bg-opacity-25 z-40" />
          <div className="fixed inset-0 z-50 flex">
            <DialogPanel className="relative ml-auto flex h-full w-full max-w-xs transform flex-col overflow-y-auto bg-white py-4 pb-12 shadow-xl z-50">
              <div className="flex items-center justify-between px-4">
                <h2 className="text-lg font-medium text-gray-900">Filters</h2>
                <button
                  type="button"
                  onClick={() => setMobileFiltersOpen(false)}
                  className="-mr-2 flex h-10 w-10 items-center justify-center rounded-md bg-white p-2 text-gray-400"
                >
                  <span className="sr-only">Close menu</span>
                  <XMarkIcon aria-hidden="true" className="h-6 w-6" />
                </button>
              </div>
              <FilterPanel
                selectedStates={selectedStates}
                setSelectedStates={setSelectedStates}
                selectedHouse={selectedHouse}
                setSelectedHouse={setSelectedHouse}
                selectedParties={selectedParties}
                setSelectedParties={setSelectedParties}
                selectedKeywords={selectedKeywords}
                setSelectedKeywords={setSelectedKeywords}
                stateSearch={stateSearch}
                setStateSearch={setStateSearch}
                keywords={keywords}
                mobile
              />
              <button
                type="button"
                onClick={clearFilters}
                className="mt-4 mx-auto block bg-red-500 text-white px-4 py-2 rounded-lg"
              >
                Clear Filters
              </button>
            </DialogPanel>
          </div>
        </Dialog>

        {/* Desktop filters */}
        <aside className="hidden lg:block w-1/5 p-4 sticky top-0 h-screen overflow-y-auto">
          <input
            type="text"
            className="w-full px-2 py-1 border border-gray-300 rounded-lg bg-white"
            placeholder="Search..."
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
          <FilterPanel
            selectedStates={selectedStates}
            setSelectedStates={setSelectedStates}
            selectedHouse={selectedHouse}
            setSelectedHouse={setSelectedHouse}
            stateSearch={stateSearch}
            setStateSearch={setStateSearch}
            selectedParties={selectedParties}
            setSelectedParties={setSelectedParties}
            selectedKeywords={selectedKeywords}
            setSelectedKeywords={setSelectedKeywords}
            keywords={keywords}
          />
          <button
            type="button"
            onClick={clearFilters}
            className="mt-4 w-full bg-red-500 text-white px-4 py-2 rounded-lg"
          >
            Clear Filters
          </button>
        </aside>

        <main className="flex-1 p-4">
          <BillList bills={filteredBills} selectedStates={selectedStates} />
        </main>
      </div>
      {/* Centered Pagination Controls */}
      <div className="flex justify-center my-4">
        <Pagination currentPage={page} totalPages={totalPages} onPageChange={setPage} />
      </div>
      <Footer />
    </div>
  );
}

export default App;
