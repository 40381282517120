import yaml from 'js-yaml';
import axios from 'axios';

let config = null;

export const loadConfig = async () => {
  if (config) return config;

  try {
    const response = await axios.get('/config.yaml');
    const yamlConfig = yaml.load(response.data);
    const env = process.env.REACT_APP_ENV || 'development';

    console.log('Env:', env);
    config = yamlConfig[env];
    console.log('Loaded config:', config);

    return config;

  } catch (error) {
    console.error('Failed to load config:', error);
    return {};
  }
};