import React from 'react';
import { Disclosure, DisclosureButton, DisclosurePanel } from '@headlessui/react';
import { PlusIcon, MinusIcon } from '@heroicons/react/20/solid';
import '../App.css';

const houses = [
  { code: 'house', name: 'House of Representatives' },
  { code: 'senate', name: 'Senate' },
];

const states = [
  { code: 'AL', name: 'Alabama' },
  { code: 'AK', name: 'Alaska' },
  { code: 'AZ', name: 'Arizona' },
  { code: 'AR', name: 'Arkansas' },
  { code: 'CA', name: 'California' },
  { code: 'CO', name: 'Colorado' },
  { code: 'CT', name: 'Connecticut' },
  { code: 'DE', name: 'Delaware' },
  { code: 'FL', name: 'Florida' },
  { code: 'GA', name: 'Georgia' },
  { code: 'HI', name: 'Hawaii' },
  { code: 'ID', name: 'Idaho' },
  { code: 'IL', name: 'Illinois' },
  { code: 'IN', name: 'Indiana' },
  { code: 'IA', name: 'Iowa' },
  { code: 'KS', name: 'Kansas' },
  { code: 'KY', name: 'Kentucky' },
  { code: 'LA', name: 'Louisiana' },
  { code: 'ME', name: 'Maine' },
  { code: 'MD', name: 'Maryland' },
  { code: 'MA', name: 'Massachusetts' },
  { code: 'MI', name: 'Michigan' },
  { code: 'MN', name: 'Minnesota' },
  { code: 'MS', name: 'Mississippi' },
  { code: 'MO', name: 'Missouri' },
  { code: 'MT', name: 'Montana' },
  { code: 'NE', name: 'Nebraska' },
  { code: 'NV', name: 'Nevada' },
  { code: 'NH', name: 'New Hampshire' },
  { code: 'NJ', name: 'New Jersey' },
  { code: 'NM', name: 'New Mexico' },
  { code: 'NY', name: 'New York' },
  { code: 'NC', name: 'North Carolina' },
  { code: 'ND', name: 'North Dakota' },
  { code: 'OH', name: 'Ohio' },
  { code: 'OK', name: 'Oklahoma' },
  { code: 'OR', name: 'Oregon' },
  { code: 'PA', name: 'Pennsylvania' },
  { code: 'RI', name: 'Rhode Island' },
  { code: 'SC', name: 'South Carolina' },
  { code: 'SD', name: 'South Dakota' },
  { code: 'TN', name: 'Tennessee' },
  { code: 'TX', name: 'Texas' },
  { code: 'UT', name: 'Utah' },
  { code: 'VT', name: 'Vermont' },
  { code: 'VA', name: 'Virginia' },
  { code: 'WA', name: 'Washington' },
  { code: 'WV', name: 'West Virginia' },
  { code: 'WI', name: 'Wisconsin' },
  { code: 'WY', name: 'Wyoming' },
];

const parties = [
    { code: 'R', name: 'Republican' },
    { code: 'D', name: 'Democrat' },
    { code: 'I', name: 'Independent' },
  ];

  const keywords = [
    "Agriculture", "Appropriations", "Armed Forces", "Banking", "Budget", "Civil Rights",
    "Commerce", "Constitution", "Crime", "Defense", "Education", "Energy", "Environment",
    "Finance", "Foreign Affairs", "Healthcare", "Housing", "Immigration", "Infrastructure",
    "Intelligence", "Judiciary", "Labor", "Natural Resources", "Science", "Small Business",
    "Social Security", "Taxation", "Technology", "Transportation", "Veterans Affairs",
    "Cryptocurrency", "Artificial Intelligence", "Cybersecurity", "LGBTQ+ Rights",
    "Climate Change", "Data Privacy", "Racial Equity", "Election Security",
    "Pandemic Response", "Space Exploration"  ];
  
  const FilterPanel = ({ selectedStates, setSelectedStates, selectedHouse, setSelectedHouse, selectedParties, setSelectedParties, stateSearch, setStateSearch, selectedKeywords, setSelectedKeywords, mobile }) => {
    const filteredStates = states.filter(state =>
      state.name.toLowerCase().includes(stateSearch.toLowerCase())
    );
  
    const handleStateChange = (stateCode) => {
      if (selectedStates.includes(stateCode)) {
        setSelectedStates(selectedStates.filter(code => code !== stateCode));
      } else {
        setSelectedStates([...selectedStates, stateCode]);
      }
    };
  
    const handlePartyChange = (partyCode) => {
      if (selectedParties.includes(partyCode)) {
        setSelectedParties(selectedParties.filter(code => code !== partyCode));
      } else {
        setSelectedParties([...selectedParties, partyCode]);
      }
    };
  
    const handleKeywordChange = (keyword) => {
      if (selectedKeywords.includes(keyword)) {
        setSelectedKeywords(selectedKeywords.filter(k => k !== keyword));
      } else {
        setSelectedKeywords([...selectedKeywords, keyword]);
      }
    };
  
    return (
      <form className={`${mobile ? 'p-4' : ''}`}>
        <Disclosure as="div" className="py-4">
          <DisclosureButton className="group flex w-full items-center justify-between bg-white py-3 text-sm text-gray-700 hover:text-gray-900">
            <span className="font-medium text-gray-900">State Representative</span>
            <span className="ml-6 flex items-center">
              <PlusIcon aria-hidden="true" className="h-5 w-5 group-data-[open]:hidden" />
              <MinusIcon aria-hidden="true" className="h-5 w-5 [.group:not([data-open])_&]:hidden" />
            </span>
          </DisclosureButton>
          <DisclosurePanel className="pt-6">
            <div className="space-y-6 max-h-64 overflow-y-auto">
              <input
                type="text"
                className="w-full px-2 py-1 border border-gray-300 rounded-lg bg-white"
                placeholder="Search states..."
                value={stateSearch}
                onChange={(e) => setStateSearch(e.target.value)}
              />
              {filteredStates.map((state, idx) => (
                <div key={state.code} className="flex items-center">
                  <input
                    id={`filter-state-${idx}`}
                    name="state"
                    type="checkbox"
                    value={state.code}
                    checked={selectedStates.includes(state.code)}
                    className="sr-only" // Hide the original checkbox
                    onChange={() => handleStateChange(state.code)}
                  />
                  <label htmlFor={`filter-state-${idx}`} className="inline-flex items-center cursor-pointer">
                    <span className="relative">
                      <span className={`block w-4 h-4 rounded-full border ${selectedStates.includes(state.code) ? 'border-transparent bg-indigo-600' : 'border-gray-300 bg-white'}`}></span>
                      {selectedStates.includes(state.code) && (
                        <span className="absolute top-0 left-0 flex items-center justify-center w-full h-full text-white">
                          <svg className="w-3 h-3 fill-current" viewBox="0 0 20 20">
                            <path d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" />
                          </svg>
                        </span>
                      )}
                    </span>
                    <span className="ml-2 text-sm text-gray-600">{state.name}</span>
                  </label>
                </div>
              ))}
            </div>
          </DisclosurePanel>
        </Disclosure>
  
        <Disclosure as="div" className="py-4">
          <DisclosureButton className="group flex w-full items-center justify-between bg-white py-3 text-sm text-gray-700 hover:text-gray-900">
            <span className="font-medium text-gray-900">House</span>
            <span className="ml-6 flex items-center">
              <PlusIcon aria-hidden="true" className="h-5 w-5 group-data-[open]:hidden" />
              <MinusIcon aria-hidden="true" className="h-5 w-5 [.group:not([data-open])_&]:hidden" />
            </span>
          </DisclosureButton>
          <DisclosurePanel className="pt-6">
            <div className="space-y-4">
              {houses.map((house, idx) => (
                <div key={house.code} className="flex items-center">
                  <input
                    id={`filter-house-${idx}`}
                    name="house"
                    type="checkbox"
                    value={house.code}
                    className="sr-only" // Hide the original checkbox
                    onChange={(e) => setSelectedHouse(e.target.value)}
                  />
                  <label htmlFor={`filter-house-${idx}`} className="inline-flex items-center cursor-pointer">
                    <span className="relative">
                      <span className={`block w-4 h-4 rounded-full border ${selectedHouse === house.code ? 'border-transparent bg-indigo-600' : 'border-gray-300 bg-white'}`}></span>
                      {selectedHouse === house.code && (
                        <span className="absolute top-0 left-0 flex items-center justify-center w-full h-full text-white">
                          <svg className="w-3 h-3 fill-current" viewBox="0 0 20 20">
                            <path d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" />
                          </svg>
                        </span>
                      )}
                    </span>
                    <span className="ml-3 text-sm text-gray-600">{house.name}</span>
                  </label>
                </div>
              ))}
            </div>
          </DisclosurePanel>
        </Disclosure>
  
        <Disclosure as="div" className="py-4">
          <DisclosureButton className="group flex w-full items-center justify-between bg-white py-3 text-sm text-gray-700 hover:text-gray-900">
            <span className="font-medium text-gray-900">Party</span>
            <span className="ml-6 flex items-center">
              <PlusIcon aria-hidden="true" className="h-5 w-5 group-data-[open]:hidden" />
              <MinusIcon aria-hidden="true" className="h-5 w-5 [.group:not([data-open])_&]:hidden" />
            </span>
          </DisclosureButton>
          <DisclosurePanel className="pt-6">
            <div className="space-y-4">
              {parties.map((party, idx) => (
                <div key={party.code} className="flex items-center">
                  <input
                    id={`filter-party-${idx}`}
                    name="party"
                    type="checkbox"
                    value={party.code}
                    checked={selectedParties.includes(party.code)}
                    className="sr-only" // Hide the original checkbox
                    onChange={() => handlePartyChange(party.code)}
                  />
                  <label htmlFor={`filter-party-${idx}`} className="inline-flex items-center cursor-pointer">
                    <span className="relative">
                      <span className={`block w-4 h-4 rounded-full border ${selectedParties.includes(party.code) ? 'border-transparent bg-indigo-600' : 'border-gray-300 bg-white'}`}></span>
                      {selectedParties.includes(party.code) && (
                        <span className="absolute top-0 left-0 flex items-center justify-center w-full h-full text-white">
                          <svg className="w-3 h-3 fill-current" viewBox="0 0 20 20">
                            <path d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" />
                          </svg>
                        </span>
                      )}
                    </span>
                    <span className="ml-3 text-sm text-gray-600">{party.name}</span>
                  </label>
                </div>
              ))}
            </div>
          </DisclosurePanel>
        </Disclosure>
  
        <Disclosure as="div" className="py-4">
          <DisclosureButton className="group flex w-full items-center justify-between bg-white py-3 text-sm text-gray-700 hover:text-gray-900">
          <span className="font-medium text-gray-900">Keywords</span>
          <span className="ml-6 flex items-center">
            <PlusIcon aria-hidden="true" className="h-5 w-5 group-data-[open]:hidden" />
            <MinusIcon aria-hidden="true" className="h-5 w-5 [.group:not([data-open])_&]:hidden" />
          </span>
        </DisclosureButton>
        <DisclosurePanel className="pt-6">
          <div className="space-y-4">
            {keywords.map((keyword, idx) => (
              <div key={keyword} className="flex items-center">
                <input
                  id={`filter-keyword-${idx}`}
                  name="keyword"
                  type="checkbox"
                  value={keyword}
                  checked={selectedKeywords.includes(keyword)}
                  className="sr-only" // Hide the original checkbox
                  onChange={() => handleKeywordChange(keyword)}
                />
                <label htmlFor={`filter-keyword-${idx}`} className="inline-flex items-center cursor-pointer">
                  <span className="relative">
                    <span className={`block w-4 h-4 rounded-full border ${selectedKeywords.includes(keyword) ? 'border-transparent bg-indigo-600' : 'border-gray-300 bg-white'}`}></span>
                    {selectedKeywords.includes(keyword) && (
                      <span className="absolute top-0 left-0 flex items-center justify-center w-full h-full text-white">
                        <svg className="w-3 h-3 fill-current" viewBox="0 0 20 20">
                          <path d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" />
                        </svg>
                      </span>
                    )}
                  </span>
                  <span className="ml-3 text-sm text-gray-600">{keyword}</span>
                </label>
              </div>
            ))}
          </div>
        </DisclosurePanel>
      </Disclosure>
    </form>
  );
};

export default FilterPanel;
  